import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: {
      dark: {
        primary: {
          base: "#1AFFD3",
          lighten1: "#FFF6E7",
        },
        accent: "#00CEFF",
        gray1: "#e0e0e0",
        background: "#0a0122",
        cardBackground: "#0a0122",
        cardSubBackground: "#0a0122",
        subtitle: "#9298AB",
        linkText: "#20BBC9",
        orange: "#0a0122",
        orange2: "#0a0122",
        orange3: "#0a0122",
        orange4: "#FFB337",
        brown: "#714A28",
        darkbrown: "#714A28",
        silver: "#362A23",
        neutral10: "#AFB9CF",
        neutral20: "#947B6D",
      },
    },
  },
});
