<template>
    <v-app class="page-bg overflow-x-hidden">
        <SnackBar />
        <GlobalLoading />
        <ConfirmDialog />
        <NavigationDrawer />
        <NotifyMobileDialog />
        <NavigationBar v-if="$vuetify.breakpoint.mdAndUp" />
        <MobileNavigationBar v-else />
        <v-main class="d-flex flex-column pt-0">
            <router-view></router-view>
        </v-main>
        <!-- <Footer /> -->
    </v-app>
</template>

<script>
import { Observer } from "mobx-vue";
import { Component, Provide, Vue } from "vue-property-decorator";
import { appProvider } from "./app-providers";
import { walletStore } from "./stores/wallet-store";

@Observer
@Component({
    components: {
        Staking: () => import("@/modules/staking/pages/staking.vue"),
        NavigationBar: () => import("@/components/NavigationBar.vue"),
        MobileNavigationBar: () =>
            import("@/components/MobileNavigationBar.vue"),
        NotifyMobileDialog: () => import("@/components/NotifyMobileDialog.vue"),
        NavigationDrawer: () => import("@/components/NavigationDrawer.vue"),
        SnackBar: () => import("@/components/snack-bar/snack-bar.vue"),
        GlobalLoading: () =>
            import("@/components/global-loading/global-loading.vue"),
        ConfirmDialog: () =>
            import("@/components/confirm-dialog/confirm-dialog.vue"),
        Footer: () => import("./components/Footer.vue"),
    },
})
export default class App extends Vue {
    mounted() {
        walletStore.start();
        appProvider.setVueRouter(this.$router);
        if (!walletStore.isMetamask) walletStore.setMobileDialog(true);
    }
}
</script>

<style lang="scss">
@import "./styles/variables.scss";
.min-height-100 {
    min-height: 100% !important;
}
.position-relative {
    position: relative !important;
}
.position-absolute {
    position: absolute !important;
}
.font-family-longshot {
    font-family: "main";
}
.page-container {
    max-width: 1100px !important;
}
.page-bg {
    background: #000 !important;
}
.text-unset {
    text-transform: unset !important;
}
.text-size-8 {
    font-size: 8px !important;
}
.text-size-12 {
    font-size: 12px !important;
}
.text-size-18 {
    font-size: 18px !important;
}
.text-size-24 {
    font-size: 24px !important;
}
.text-size-36 {
    font-size: 36px !important;
}
.text-size-56 {
    font-size: 56px !important;
}
.v-application {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
}
.border-radius-8 {
    border-radius: 8px !important;
}
.border-radius-16 {
    border-radius: 16px !important;
}
.transparent-bg {
    background: transparent !important;
}
.btn-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 24px;
    letter-spacing: 0.02em;
}
.full-width {
    width: 100% !important;
}
.full-height {
    height: 100% !important;
}
.font-longshot {
    font-family: "main" !important;
}
.border-right-dotted {
    background-image: linear-gradient(#ffc04e 33%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 1px 5px;
    background-repeat: repeat-y;
}
.border-bottom-dotted {
    background-image: linear-gradient(
        to right,
        #1affd3 33%,
        rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;
}
.dialog-title {
    font-family: "BebasNeue" !important;

    font-style: normal;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 28px;
    letter-spacing: 0.02em;
}
.section-title {
    font-style: normal;
    font-weight: 600;
    font-size: 28px !important;
    line-height: 42px;
}
.content-title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px !important;
    line-height: 28px;
    letter-spacing: 0.02em;
}
.content-big-number {
    font-style: normal;
    font-weight: bold !important;
    font-size: 36px !important;
    line-height: 48px;
    letter-spacing: 0.02em;
}
.content-small-text {
    font-weight: normal;
    font-size: 12px !important;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.02em;
}
.avatar-frame {
    position: absolute;
    max-width: 444px;
    max-height: 444px;
}
.card-bg {
    background: var(--v-cardBackground-base) !important;
}
.card-light-bg {
    background: var(--v-cardSubBackground-base) !important;
}
.card-border {
    border: 1px solid #292f49 !important;
    box-sizing: border-box;
}
.big-card-border {
    border: 3px solid #292f49 !important;
}
.gray-card-border {
    border: 1px solid var(--v-subtitle-base) !important;
}
.big-light-card-border {
    border: 3px solid #3a415f !important;
}
.light-gray-card-border {
    border: 1px solid #3a415f !important;
}
.bigger-card-border {
    border: 5px solid #292f49 !important;
}
.dashed-border {
    border: 1px dashed #3a415f;
}
.white-border {
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.cursor-pointer:hover {
    cursor: pointer;
}
.primary-border {
    border: 1px solid var(--v-primary-base) !important;
}
.big-primary-border {
    border: 3px solid var(--v-primary-base) !important;
}
.bigger-primary-border {
    border: 6px solid var(--v-primary-base) !important;
}
.big-gray-border {
    border: 3px solid #afb9cf;
}
.bottom-border {
    border-bottom: 1px solid #3a415f !important;
}
.card-hover:hover {
    background: var(--v-cardSubBackground-base) !important;
}
.normal-card-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 26px;
    letter-spacing: 0.02em;
}
.small-card-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 20px;
    letter-spacing: 0.02em;
}
.info-field-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 24px;
    letter-spacing: 0.02em;
}
.info-field-number {
    font-style: normal;
    font-weight: bold;
    font-size: 24px !important;
    line-height: 32px;
    letter-spacing: 0.02em;
}
.stat-size {
    max-width: 120px;
}
.nav-btn-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 24px;
}
.info-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 24px;
    letter-spacing: 0.02em;
}
.info-number {
    font-style: normal;
    font-weight: bold;
    font-size: 24px !important;
    line-height: 32px;
    letter-spacing: 0.02em;
}
.theme--dark {
    .v-text-field--solo > .v-input__control > .v-input__slot {
        background: #026899 !important;
    }
    .v-pagination .v-pagination__item {
        margin: 2px;
        box-shadow: none;
    }
    .v-pagination .v-pagination__navigation {
        margin: 0px;
        background: transparent !important;
        box-shadow: none;
    }
}

.v-input--selection-controls .v-input__slot > .v-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #ffffff;
}
.avatar-index {
    position: relative;
    left: 8px;
    top: 8px;
}

.fading-from-bottom {
    animation: fadeFromBottom ease-out 0.8s;
    -webkit-animation: fadeFromBottom ease-out 0.8s;
    -moz-animation: fadeFromBottom ease-out 0.8s;
    -o-animation: fadeFromBottom ease-out 0.8s;
    -ms-animation: fadeFromBottom ease-out 0.8s;
}

.dialog-bg {
    background: #1d1d1d !important;
}
.section-border {
    border: 1px solid #3a415f !important;
    box-sizing: border-box;
}
.evilFont {
    font-family: "EvilEmpire" !important;
}
.table-first-heading {
    background: var(--v-cardBackground-base) !important;
    border-top-left-radius: 16px !important;
}
.table-last-heading {
    background: var(--v-cardBackground-base) !important;
    border-top-right-radius: 16px !important;
}
.v-tab {
    text-transform: none !important;
}
.price-bnb {
    font-style: normal;
    font-weight: bold;
    font-size: 24px !important;
    line-height: 32px;
    letter-spacing: 0.02em;
}
.price-usd {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
}
.overflow-hidden {
    overflow: hidden !important;
}
.overflow-x-hidden {
    overflow-x: hidden !important;
}
.info-field {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
}
.info-number {
    font-style: normal;
    font-weight: bold;
    font-size: 20px !important;
    line-height: 28px;
    letter-spacing: 0.02em;
}
.estimated-number {
    font-style: normal;
    font-weight: bold;
    font-size: 36px !important;
    line-height: 48px;
    letter-spacing: 0.02em;
}
.estimated-title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px !important;
    line-height: 28px;
    letter-spacing: 0.02em;
}
.estimated-small-number {
    font-style: normal;
    font-weight: bold;
    font-size: 28px !important;
    line-height: 32px;
    letter-spacing: 0.02em;
}
.estimated-content {
    max-width: 360px;
}
.font-weight-semilight {
    font-weight: 400;
}
.font-weight-normal {
    font-weight: 500;
}
.v-pagination__item--active {
    font-weight: 600;
    font-size: 18px !important;
}
.v-pagination__more {
    width: auto !important;
}
.v-input__prepend-inner,
.v-input__append-inner {
    margin: auto !important;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent !important;
}
::-webkit-scrollbar {
    width: 6px;
    background-color: transparent !important;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--v-subtitle-base);
}
@keyframes fadeFromBottom {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Mitr:wght@700&display=swap");

@font-face {
    font-family: "BebasNeue";
    src: url(~@/assets/fonts/UTM-Bitsumishi-Pro.ttf);
}
// LongShot font
@font-face {
    font-family: "main";
    src: url(~@/assets/fonts/UTM-Bitsumishi-Pro.ttf);
}
@font-face {
    font-family: "Bitsumishi";
    src: url(~@/assets/fonts/UTM-Bitsumishi-Pro.ttf);
}
@font-face {
    font-family: "Elemental";
    src: url(~@/assets/fonts/Elemental-End.ttf);
}
@font-face {
    font-family: "ChakraPetch";
    src: url(~@/assets/fonts/ChakraPetch-Regular.ttf);
}
</style>
