import Axios from "axios";
import { walletStore } from "@/stores/wallet-store";
import { get } from "lodash-es";
import { NftModel } from "@/models/nft-model";
export type ApiRouteType = "nfts";

export class ApiHandler<T> {
  constructor(private axios, private route: ApiRouteType) {}

  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, { params });
    return res.data;
  }

  async create(model: T): Promise<T> {
    const res = await this.axios.post(this.route, model);
    return res.data;
  }

  async delete(id: any): Promise<T> {
    const res = await this.axios.delete(`${this.route}/${id}`);
    return res.data;
  }

  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: "createdAt:DESC", _limit: 25, _start: 0 };
    params = { ...settingDefault, ...settings, ...(params ?? {}) };
    const res = await this.axios.get(this.route, { params });
    const lst = res.data;
    // if (this.route === "pool") {
    //   lst.forEach((element) => {
    //     if (element.data?.chainId) {
    //       element.chainId = element.data.chainId;
    //     } else if (isNaN(+element.chainId)) {
    //       element.chainId = +element.chainId;
    //     }
    //   });
    // }
    return lst;
  }

  async findOne<T>(id: any): Promise<T> {
    let res: any;
    if (id) {
      res = await this.axios.get(`${this.route}/${id}`);
    } else {
      res = await this.axios.get(`${this.route}`);
    }
    const result = res.data;
    // if (this.route === "pool") {
    //   if (result.data?.chainId) {
    //     result.chainId = result.data.chainId;
    //   } else if (isNaN(+result.chainId)) {
    //     result.chainId = +result.chainId;
    //   }
    // }
    return result;
  }

  async update(id: any, model: T): Promise<T> {
    let res: any;
    if (id) {
      res = await this.axios.put(`${this.route}/${id}`, model);
    } else {
      res = await this.axios.put(`${this.route}`, model);
    }
    return res.data;
  }
}

export class ApiService {
  axios = Axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT });

  async getNFT(nftId): Promise<NftModel> {
    try {
      const res = await this.axios.post(`detail?token=${process.env.VUE_APP_NFT_ADDRESS}&tokenId=${nftId}`);
      const nft = get(res, "data.data.nft", "");
      if (nft) {
        const attributes = nft.metadataContent ? JSON.parse(nft.metadataContent).attributes : null;
        let nftModel = {
          id: nft.tokenId,
          name: nft.name,
          description: nft.description,
          animUrl: "https://nftimage.matrixgpt.ai/" + nft.animUrl,
          metadataUrl: nft.metadataUrl,
        } as NftModel;

        if (attributes && attributes.length) {
          nftModel = {
            ...nftModel,
            level: attributes.find((data) => data.key === "LEVEL").value,
            atk: attributes.find((data) => data.key === "ATK").value,
            hp: attributes.find((data) => data.key === "HP").value,
            spd: attributes.find((data) => data.key === "SPD").value,
            mp: attributes.find((data) => data.key === "MP").value,
          };
        }

        return nftModel;
      }
      return {};
    } catch (error) {
      console.error(error);
      return {};
    }
  }
}

export const apiService = new ApiService();
